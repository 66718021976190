import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Image from "gatsby-image"
import styles from "../css/BlogPost.module.css"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"
import ShareButtons from "../components/ShareButtons"
import Tags from "../components/Tags"
// import ButtonLink from "../components/ButtonLink"

const BlogTemplate = ({ data, location }) => {
  const {
    frontmatter: { title, date, author, tags, description, slug },
    body,
    featuredImg,
  } = data.post
  const { href } = location
  const disqusConfig = {
    url: href,
    identifier: slug,
    title: title,
  }

  // const {
  //   state: { prevUrl },
  // } = location
  // console.log(prevUrl)

  // let backButton = null
  // // if (prevUrl && prevUrl !== "/blog") {
  // if (prevUrl) {
  //   backButton = <ButtonLink to={prevUrl}>Back</ButtonLink>
  // }

  return (
    <Layout
      title={title}
      description={description}
      image={featuredImg.childImageSharp.fluid.src}
      contentType="article"
    >
      <div className="default-center">
        {/* <div className={styles.backBtn}>{backButton}</div> */}
        <section className={styles.blog}>
          <h1 className={styles.textMargin}>{title}</h1>
          <div className={`${styles.postMeta} ${styles.textMargin}`}>
            <div className={styles.blogAuthorDate}>
              <div className={styles.blogAuthor}>{author}</div>
              <div className={styles.blogAuthor}>{date}</div>
            </div>
            <div className={styles.blogAuthorDate}>
              <ShareButtons url={href} />
            </div>
          </div>
          <Image
            fluid={featuredImg.childImageSharp.fluid}
            className={styles.featuredImage}
          />
          <article className={`${styles.post} ${styles.textMargin}`}>
            <MDXRenderer>{body}</MDXRenderer>
          </article>
          <div className={`${styles.postFooter} ${styles.textMargin}`}>
            <Tags tags={tags} />
            <ShareButtons url={href} />
          </div>
          <div className={`${styles.disqus} ${styles.textMargin}`}>
            <CommentCount config={disqusConfig} placeholder={"..."} />
            <Disqus config={disqusConfig} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        featuredImgUrl
        date(formatString: "MMM Do, YYYY")
        author
        tags
        slug
        title
        description
      }
      body
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`

export default BlogTemplate
