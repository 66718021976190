import React from "react"
import styles from "../css/ShareButtons.module.css"
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare } from "react-icons/fa"
import { IconContext } from "react-icons"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"

const ShareButtons = ({ url }) => {
  return (
    <IconContext.Provider value={{ size: "1.5em" }}>
      <div className={styles.shareButtons}>
        <FacebookShareButton url={url} className={styles.shareButton}>
          <FaFacebookSquare />
        </FacebookShareButton>
        <LinkedinShareButton url={url} className={styles.shareButton}>
          <FaLinkedin />
        </LinkedinShareButton>
        <TwitterShareButton url={url} className={styles.shareButton}>
          <FaTwitterSquare />
        </TwitterShareButton>
      </div>
    </IconContext.Provider>
  )
}

export default ShareButtons
